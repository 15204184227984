<template>
  <section>
    <div
      class="edit-image-tools flex items-center flex-wrap-reverse mb-1 images-edit-actions"
    >
      <div class="flex items-center mb-3">
        <v-btn
          v-for="tool in tools"
          :key="tool.id"
          :id="tool.id"
          color="primary"
          class="mx-1 action-btn"
          small
          :class="`${tool.id} ${!isActive(tool) ? 'outlined' : 'depressed'}`"
          @click="onSelectTool(tool.type)"
        >
          <v-icon size="medium">{{ tool.icon }}</v-icon>
          <span class="mx-2">{{ tool.label }}</span>
        </v-btn>
      </div>

      <div class="flex items-center ms-auto mb-3">
        <v-btn
          color="primary"
          class="mx-1 action-btn"
          :class="isSquare ? 'outlined' : 'depressed'"
          small
          @click="onToggleAspectRatio"
        >
          <v-icon size="medium">mdi-aspect-ratio</v-icon>
          <span class="mx-2">{{ isSquare ? "ריבוע" : "רגיל" }}</span>
        </v-btn>

        <v-btn
          color="primary"
          class="mx-1 action-btn"
          small
          :class="!!activeTool ? 'outlined' : 'depressed'"
          @click="onSelectTool(null)"
        >
          <v-icon size="medium">mdi-crop</v-icon>
          <span class="mx-2">זום</span>
        </v-btn>

        <v-btn
          color="primary"
          class="mx-1 action-btn depressed"
          small
          @click="onRotate"
        >
          <v-icon size="medium">mdi-crop-rotate</v-icon>
          <span class="mx-2">סיבוב 90°</span>
        </v-btn>

        <v-btn
          id="clearBtn"
          color="primary"
          class="mx-1 action-btn depressed"
          :disabled="!canUndo"
          small
          @click="onUndo"
        >
          <v-icon size="medium">mdi-undo</v-icon>
          <span class="mx-2">חזור</span>
        </v-btn>

        <v-btn
          id="clearBtn"
          color="primary"
          class="mx-1 action-btn depressed"
          small
          @click="onReset"
        >
          <v-icon size="medium">mdi-cancel</v-icon>
          <span class="mx-2">נקה הכל</span>
        </v-btn>
      </div>
    </div>
    <div class="edit-image-toolbar flex justify-between items-start mb-1">
      <div v-if="showColorsOptions" class="edit-image-toolbar-colors">
        <span
          v-for="color in colors"
          :key="color"
          :data-color="color"
          :class="{ 'is-active': currentColor === color }"
          :style="{ backgroundColor: color }"
          @click="onSetColor(color)"
        ></span>
      </div>

      <div v-if="showTextOptions" class="flex items-center ms-auto">
        <select
          :value="fontSize"
          class="font-size-select"
          @input="onSetFontSize"
        >
          <option v-for="(size, index) in fontSizes" :key="index" :value="size">
            {{ size }}
          </option>
        </select>

        <v-btn
          class="action-btn mr-2"
          color="primary"
          :class="isBold ? 'depressed' : 'outlined'"
          small
          @click="onToggleBold"
        >
          B
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useEditImage } from "@/composables/useEditImage";
import {
  colors,
  fontSizes,
  toolType,
  tools,
} from "@/components/EditImageModal-v3/const";

const props = defineProps({
  imageLayerRef: Object,
});

const {
  state: { activeTool, canUndo, currentColor, fontSize, isBold, isSquare },
  options: {
    toggleAspectRatio,
    setColor,
    setFontSize,
    selectTool,
    toggleBold,
    undoAction,
    resetCanvas,
    rotateCanvas,
  },
} = useEditImage();

// Tools configuration

// Event handlers
const onSelectTool = (tool) => selectTool(tool);
const onSetColor = (color) => setColor(color);
const onSetFontSize = (event) => setFontSize(Number(event.target.value));
const onToggleBold = () => toggleBold();
const onUndo = () => undoAction();
const onReset = () => resetCanvas();
const onRotate = () => rotateCanvas(props.imageLayerRef);
const onToggleAspectRatio = () => toggleAspectRatio();

// Dynamic options for UI
const showColorsOptions = computed(() => !!activeTool.value);
const showTextOptions = computed(() => activeTool.value === toolType.text);

const isActive = (tool) => {
  return activeTool.value === tool.type;
};
</script>

<style scoped lang="scss">
.edit-image-toolbar-colors span {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 4px;

  @media (max-width: 550px) {
    width: 20px;
    height: 20px;
    margin: 0 2px;
  }
}

.font-size-select {
  background: rgb(255, 255, 255);
  border: 1px solid #5e21d2;
  border-radius: 4px;
  text-align: center;
  width: 40px;
  color: #5e21d2;
  font-size: 0.75rem;
  font-weight: 500;
  position: relative;
  appearance: auto;
  cursor: pointer;
  min-height: 24px;
}

.edit-image-tools .v-btn__content {
  align-items: end;
}

.edit-image-toolbar-colors span.is-active {
  border: 2px solid #000;
}

.action-btn {
  background-color: var(--color-purple) !important;
  height: 24px !important;
  padding: 0 5px !important;
  font-size: 12px;
  margin: 0 5px 0 0 !important;
  letter-spacing: 0;
  min-width: 0;

  &.outlined {
    border: 1px solid var(--color-purple) !important; /* Primary color border */
    background: transparent !important;
    box-shadow: none !important;
    color: var(--color-purple) !important;
  }

  &.depressed {
    box-shadow: none !important;
    background-color: var(--color-purple) !important;
  }

  .v-btn__content {
    span {
      margin: 0 1px 0 0 !important;
    }
  }

  i {
    font-size: 14px !important;
  }
}

.action-btn {
  @media (max-width: 766px) {
    padding: 0 0px !important;
    font-size: 11px;
    margin: 0 2px 0 0 !important;
    width: 30px !important;

    .v-btn__content {
      span {
        margin: 0 0px 0 0 !important;
        display: none;
      }
    }

    i {
      font-size: 14px !important;
    }
  }

  @media (max-width: 550px) {
    margin: 0 3px 0 0 !important;
    &.line {
      display: none;
    }
    i {
      font-size: 16px !important;
    }
  }
}
</style>
