<template>
  <v-card v-if="upload" outlined class="edit-image-modal">
    <div>
      <v-card-title class="flex items-center justify-between flex-wrap mb-0">
        <span>עריכת תמונה</span>
        <v-btn
          icon
          small
          flat
          class="mr-2 max-h-6 max-w-6 bg-inherit"
          @click="emit('close-edit-image')"
        >
          <v-icon size="15">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="edit-image-content">
        <EditImageToolbar :imageLayerRef="imageLayerRef" />

        <div
          class="edit-image-canvas relative"
          id="canvas-container"
          :class="{ 'cursor-move': !state.activeTool }"
        >
          <v-stage
            ref="stageRef"
            :config="state.stageConfig.value"
            @wheel="options.handleZoom"
            @mousedown="options.startDrawing"
            @mousemove="options.draw"
            @mouseup="options.finishDrawing"
            @touchstart="options.onTouchStart"
            @touchmove="options.onTouchMove"
            @touchend="options.onTouchEnd"
          >
            <v-layer ref="imageLayerRef">
              <v-image
                :image="state.image.value"
                :x="state.imageX.value"
                :y="state.imageY.value"
                :width="state.imageWidth.value"
                :height="state.imageHeight.value"
                :draggable="canDragImage"
                @dragmove="options.constrainImage"
              />
            </v-layer>

            <v-layer ref="shapesLayerRef"></v-layer>
          </v-stage>
        </div>

        <v-btn
          block
          class="save-btn depressed"
          color="purple"
          x-large
          @click="finishEditImage"
        >
          שמירת תמונה
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useEditImage } from "@/composables/useEditImage";
import EditImageToolbar from "@/components/EditImageModal-v3/EditImageToolbar.vue";

const props = defineProps({
  upload: Object,
  closeEditImage: Function,
  updateEditImage: Function,
  mediaUrl: String,
});

const stageRef = ref(null);
const imageLayerRef = ref(null);
const shapesLayerRef = ref(null);

const { options, state } = useEditImage();

const emit = defineEmits(["update-edit-image", "close-edit-image"]);

onMounted(() => {
  const refs = {
    stageRef,
    imageLayerRef,
    shapesLayerRef,
  };
  options.init(refs, { ...props.upload, mediaUrl: props.mediaUrl });
});

onUnmounted(() => {
  options.destroyCanvas();
  stageRef.value = null;
  imageLayerRef.value = null;
  shapesLayerRef.value = null;
});

const canDragImage = computed(() => {
  if (state.activeTool.value) return false;

  const scale = state.stageConfig.value.scaleX;
  const scaledWidth = state.imageWidth.value * scale;
  const scaledHeight = state.imageHeight.value * scale;
  const canvasWidth = state.stageConfig.value.width;
  const canvasHeight = state.stageConfig.value.height;

  // Allow dragging if image overflows canvas in either dimension
  return scaledWidth > canvasWidth || scaledHeight > canvasHeight;
});

const finishEditImage = () => {
  const stage = stageRef.value.getStage();
  // If the stage has been rotated (e.g. stage.rotation(90)),
  // the call below will reflect that rotation in the exported image.

  const dataURL = stage.toDataURL({
    pixelRatio: 1, // Adjust if you want higher resolution exports
  });

  emit("update-edit-image", dataURL);
  emit("close-edit-image");
};
</script>

<style lang="scss">
.edit-image-modal {
  .edit-image-content {
    padding: 16px;

    @media (max-width: 550px) {
      padding: 12px;
    }
  }

  .edit-image-canvas {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .save-btn {
    background-color: var(--color-purple) !important;
    height: 44px;
    @media (max-width: 550px) {
      margin-inline-end: 4px;
    }
  }
}
</style>
