export const toolType = Object.freeze({
	freeDraw: "FREE_DRAW",
	ellipse: "ELLIPSE",
	arrow: "ARROW",
	line: "LINE",
	text: "TEXT",
});


export const tools = [
	{ id: "draw", type: toolType.freeDraw, icon: "mdi-pencil", label: "שרטוט" },
	{
		id: "circle",
		type: toolType.ellipse,
		icon: "mdi-checkbox-blank-circle-outline",
		label: "עיגול",
	},
	{
		id: "arrow",
		type: toolType.arrow,
		icon: "mdi-arrow-up-bold-outline",
		label: "חץ",
	},
	{
		id: "line",
		type: toolType.line,
		icon: "mdi-minus",
		label: "קו",
	},
	{
		id: "text",
		type: toolType.text,
		icon: "mdi-format-color-text",
		label: "טקסט",
	},
];

// Predefined colors and font sizes
export const colors = [
	"#5D21D2",
	"#09916E",
	"#1e429f",
	"#EF810E",
	"#DF302F",
	"#000000",
	"#ffffff",
	"#DADADA",
];

export const fontSizes = [
	12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];